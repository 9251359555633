.filter-select-wrapper {



    .filter-select {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.16);
        border-color: transparent;

        margin: 2.5px;
        font-size: 14px;
        padding-top: 2.5px;
        padding-bottom: 2.5px;
        display: inline-block;
        height: 32px;
        background-color: #fff;
        text-align: left;

        i {
            transition: transform 0.3s ease;
            transform: rotate(0);
        }
    }
    
    .filter-select.active {
        box-shadow: 0 15px 0 #fff, 0 0px 15px rgba(0,0,0, .16);
        i {
            transition: transform 0.3s ease;
            transform: rotate(180deg);
        }
    }
    
    .filter-select-list {
        transition: all 0.3s ease;
        height: 0;
        z-index: 1;
        background: white;
        padding: 10px;
        margin-left: 2.5px;
        margin-top: 0px;
        li {
            height: 35px;
            a {
                margin-top: auto;
                margin-bottom: auto;
                text-decoration: none;
            }
        }
    }
    .filter-select.active + .filter-select-list {
        box-shadow: 0 -12px 0 #fff, 0 0px 15px rgba(0,0,0, .16);
    }

}
