//image
img {
    width: 100%;
    max-width: 100%;
    display: block;
}

.bg-image-cover {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.bg-image-contain {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.bg-image {
    background-repeat: no-repeat;
    background-position: center;
}

img.border-1 {
    border: 1px solid #DBDBD6;
}

@include media-breakpoint-up(md) {
    .md-object-fit-cover {
        object-fit: cover;
        height: 100%;
    }
}
.cssImgOf {
    object-fit: cover;
    height: calc(50% - (15px / 2));
    @include media-breakpoint-up(xxl) {
        height: calc(50% - (20px / 2));
    }
    @include media-breakpoint-up(xxxl) {
        height: calc(50% - (25px / 2));
    }
}

.lightbox {
    cursor: pointer;
}
.lightbox-carousel .ratio img {
    background-color: white;
}
.lightbox-carousel .ratio {
    max-height: 90vh;
}


body.modal-open.show-lightbox-fullscreen {
    .modal-backdrop.show {
        --bs-backdrop-bg: #fff;
        --bs-backdrop-opacity: 0.95;
    }

    .modal-dialog {
        max-width: 100vw;
        max-height: 100vh;
        .carousel-inner {
            .ratio {
                background-color: rgba(255,255,255,0.95) !important;
            }
        }
    }
    
    .btn-close {
        width: 46px;
        height: 46px;
        opacity: 0.9 !important;
        top: 10px !important;
        right: 20px !important;
        position: fixed!important;
        svg {
            fill: var(--bs-grey-dark);
        }

    }

}
