.filters {
    button {
        background-color: $white;
    }

    button:focus {
        background-color: var(--bs-white);
        color: var(--bs-black);
    }

    button.active:focus {
        background-color: var(--bs-black);
        color: var(--bs-white);
        box-shadow:none;
    }
}

// filter fixed
@include media-breakpoint-up(sm) {
    body.filterbar-fixed {
        .container-masonry-1 {
        //    padding-top:75px;
        }

        .container-fluid-filters {
            position: fixed;
            top:90px;
            right:0;
            z-index: 1;
            @include media-breakpoint-up(lg) {
                z-index: 1002;
            }
        }
    }

}

.container-fluid-filters {
    right:0;
    z-index: 1;
    position: absolute;

    @include media-breakpoint-down(sm) {
        position: fixed;
    }

    .col {
        padding:14px;
        padding-right:0;
        margin-right: 6px;
    }

    .filter-on-off {
        height: 32px;
        width: 32px;
        flex-shrink:0;
        padding:0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .filter-on-off,
    .filter-button,
    .filter-button-style {
        margin: 2.5px;
        box-shadow: 0 0 15px rgb(0 0 0 / 16%);
        border-color: transparent;
        font-size: 14px;
        padding-top: 2.5px;
        padding-bottom: 2.5px;
    }
    
    .filter-button.filter-button-first,
    .filter-button-style.filter-button-first {
        margin-left: 15px;
    }

    .col {
        transition: all 0.3s ease;
    }

    .col.open {
        width: 100%;
        max-width: 100%;

        .filter-on-off {
            transition: all 0.3s ease;
            transform: rotate(0deg);
        }

        .filter-button {
            display: block;
        }

        .fa-arrow-right {
            transition: all 0.3s ease;
            transform: rotate(00deg);
            display: inline-block;
        }

        .fa-bars-filter {
            transition: all 0.3s ease;
            transform: rotate(-90deg);
            display: none;
        }
    }

    .col.close {
        width: 25%;
        max-width: 25%;

        .filter-button, .filter-button-style {
            display: none;
        }

        .fa-arrow-right {
            transform: rotate(90deg);
            display: none;
        }

        .fa-bars-filter {
            transform: rotate(0deg);
            display: inline-block;
        }

    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.container-fluid-filters-produktausfuehrung::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.container-fluid-filters-produktausfuehrung {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.slider-produktausfuehrung {
    border: 2px solid var(--bs-gray);
    width: 26px;
    height: 26px;
    border-radius: 50%;
    margin-top: 6px;
    top: 14px;
    z-index: 1;
    @include media-breakpoint-up(xl) {
        display: none !important;
    }
}

#slideleft {
    aspect-ratio: 1/1;
    margin-left: 16px;
    @include media-breakpoint-up(sm) {
        margin-left: 20px
    }
    @include media-breakpoint-up(md) {
        margin-left: 48px;
    }
    @include media-breakpoint-up(lg) {
        margin-left: 32px;
    }
}

#slideright {
    aspect-ratio: 1/1;
    margin-right: 16px;
    @include media-breakpoint-up(sm) {
        margin-right: 20px
    }
    @include media-breakpoint-up(md) {
        margin-right: 48px;
    }
    @include media-breakpoint-up(lg) {
        margin-right: 32px;
    }
}

.container-fluid-filters-produktausfuehrung {
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    left:0;
    z-index: 1;
    position: relative;

    .col {
        margin:0;
        padding: 14px;
        @include media-breakpoint-up(sm) {
            padding-left: 32px;
            padding-right: 32px;
        }
        @include media-breakpoint-up(md) {
            padding-left: 48px;
            padding-right: 48px;
        }
        @include media-breakpoint-up(lg) {
            padding-left: 32px;
            padding-right: 32px;
        }
        @include media-breakpoint-up(xl) {
            padding-left: 64px;
            padding-right: 64px;
        }
        @include media-breakpoint-up(xxl) {
            padding-left: 80px;
            padding-right: 80px;
        }
        @include media-breakpoint-up(xxxl) {
            padding-left: 90px;
            padding-right: 90px;
        }
        @include media-breakpoint-up(xxxxl) {
            padding-left: 0;
            margin-left: auto;
            margin-right: auto;
            max-width: 1760px;
        }
    }

    .filter-button-first {
        margin-left: 10px;
    }

    .filter-on-off {
        height: 32px;
        width: 32px;
        flex-shrink:0;
        padding:0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .filter-on-off,
    .filter-button, .filter-button-style {
        margin: 2.5px;
        box-shadow: 0 0 15px rgb(0 0 0 / 16%);
        border-color: transparent;
        font-size: 14px;
        padding-top: 2.5px;
        padding-bottom: 2.5px;
    }

    .col {
        transition: all 0.3s ease;
    }

    .col.open {
        width: 100%;
        max-width: 100%;

        .filter-on-off {
            transition: all 0.3s ease;
            transform: rotate(0deg);
        }

        .filter-button, .filter-button-style {
            display: block;
        }

        .fa-arrow-right {
            transition: all 0.3s ease;
            transform: rotate(00deg);
            display: inline-block;
        }

        .fa-bars-filter {
            transition: all 0.3s ease;
            transform: rotate(-90deg);
            display: none;
        }
    }

    .col.close {
        width: 25%;
        max-width: 25%;

        .filter-button, .filter-button-style {
            display: none;
        }

        .fa-arrow-right {
            transform: rotate(90deg);
            display: none;
        }

        .fa-bars-filter {
            transform: rotate(0deg);
            display: inline-block;
        }

    }
}


#plpFilter {
    z-index: 101;
    right:0;
    top: calc(70vh + 14px);
    @include media-breakpoint-up(md) {
        top: 78px;
    }
}

#plpFilterModal {
    z-index: 4001;

    .btn-close {
        opacity: 1;
    }
    @include media-breakpoint-up(sm) {
        .modal-dialog {
            margin-left: calc(100vw - 500px)!important;
        }
    }

    .accordion-button::after {
        background-image: url('data:image/svg+xml,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'%23ffffff\'><path fill-rule=\'evenodd\' d=\'M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z\'/></svg>') !important;
    }

    .accordion-item {
        border-bottom: 1px solid var(--bs-white);
        background-color: var(--bs-black);
    }

    .accordion-item:first-child {
        border-top: 1px solid var(--bs-white);
    }

    .accordion-body {
        padding-top:0;
    }

    .form-check-input {
        background-color: var(--bs-black);
        border: 1px solid var(--bs-white);
    }

    .form-check-input:checked {
        background-color: var(--bs-white);
    }
}

#plpFilterOffcanvas .label-wrapper.form-check {
    display: table;
}

//open / close accordion icon override
.productFamilyFilter {
    .accordion-button {
        &::after {
            background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><path d="M15.477.908,15.49,28.2,1.375,17.679s0,2.155,0,2.155l14.99,11.075L31.375,19.8V17.689L17.24,28.2,17.246.908Z" fill="white"/></svg>') !important;
        }

        &.collapsed {
            &::after {
                background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><path d="M15.477.908,15.49,28.2,1.375,17.679s0,2.155,0,2.155l14.99,11.075L31.375,19.8V17.689L17.24,28.2,17.246.908Z" fill="white"/></svg>') !important;
            }
        }
    }
}

.accordion-button {
    &::after {
        background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><path d="M15.477.908,15.49,28.2,1.375,17.679s0,2.155,0,2.155l14.99,11.075L31.375,19.8V17.689L17.24,28.2,17.246.908Z" fill="DarkGray"/></svg>') !important;
    }

    &.collapsed {
        &::after {
            background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><path d="M15.477.908,15.49,28.2,1.375,17.679s0,2.155,0,2.155l14.99,11.075L31.375,19.8V17.689L17.24,28.2,17.246.908Z" fill="DarkGray"/></svg>') !important;
        }
    }
}

.moreButton {
    font-size: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
    @include media-breakpoint-up(sm) {
        font-size: 20px;
        padding-top: 6px;
        padding-bottom: 6px;
    }
}

.col-entrytypenbuttons {
    margin-bottom: -18px;
}

.filter-button-icon {
    height: 35px;
    width: 35px;
}

.filter-button-text {
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 15px;
    padding-right: 15px;
    @include media-breakpoint-up(md) {
        padding-right: calc($spa + 15px);
    }
}


#indicator {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    z-index: -1;
    display: none;
}


#indicator.htmx-request {
    display: flex;
    z-index: 3200;
    background: rgba(0, 0, 0, 0.7);
}

.btn-hover-yellow:hover {
    background-color:var(--bs-yellow);
    border-color: var(--bs-yellow);
    color: var(--bs-black);
}

.filter-button-style:hover {
    background-color: var(--bs-white);
    color: var(--bs-black);
}

input:checked + .filter-button-style {
    background-color:var(--bs-yellow);
    border-color: var(--bs-yellow);
    color: var(--bs-black);
}

.container-fluid-filters .filter-button.filter-button-entrytype {
    border: 2px solid black;
}
