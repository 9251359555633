.person-title {

    font-family: 'Helvetica Now', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 1.4; // 24px

    @include media-breakpoint-up(sm) {
        font-size: 22px;
    }
    @include media-breakpoint-up(lg) {
        font-size: 20px;
        line-height: 30px;
    }
    @include media-breakpoint-up(xl) {
        font-size: 22px;
    }
    @include media-breakpoint-up(xxxl) {
        font-size: 26px;
        line-height: 34px;
    }
    @include media-breakpoint-up(xxxxl) {
        font-size: 30px;
        line-height: 38px;
    }

}

.team-member-component {
    .team-member {
        transition: all .35s ease-in-out;
        overflow: hidden;

        h2.h3 {

            font-family: 'Helvetica Now', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 1.4; // 24px

            @include media-breakpoint-up(sm) {
                font-size: 22px;
            }
            @include media-breakpoint-up(lg) {
                font-size: 20px;
                line-height: 30px;
            }
            @include media-breakpoint-up(xl) {
                font-size: 22px;
            }
            @include media-breakpoint-up(xxxl) {
                font-size: 26px;
                line-height: 34px;
            }
            @include media-breakpoint-up(xxxxl) {
                font-size: 30px;
                line-height: 38px;
            }
            
        }


        //margin-bottom: 16px;
        margin-bottom: 30px;
        @include media-breakpoint-up(sm) {
            margin-bottom: 60px;
        }
        @include media-breakpoint-up(md) {
            margin-bottom: 90px;
        }
        @include media-breakpoint-up(xxl) {
            margin-bottom: 120px;
        }
        @include media-breakpoint-up(xxxl) {
            margin-bottom: 150px;
        }

        .animated-hover {
            &:hover {
                .plus-icon {
                    opacity: 0;
                }
                .team-member-image {
                    opacity: 0;
                }
                .quote-overlay {
                    opacity: 1;
                    margin-bottom: 0;
                }
            }
        }
        .plus-icon {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--bs-white);
            background-color: rgba(0,0,0,0.85);
            width: 2.5rem;
            height: 2.5rem;
            right: 0;
            bottom: 0;
            opacity: 1;
            svg {
                width: 32px;
                height: 32px;
            }
        }

        .team-member-image {
            transition: all .35s ease-in-out;
            aspect-ratio: 1/1;
            object-fit: cover;
            object-position: top;
        }

        .quote-overlay {
            opacity: 0;
            margin-bottom: 100%;
            transition: all .5s ease-in-out;

            font-size: 14px;
            line-height: 1.5;
            @include media-breakpoint-up(sm) {
                font-size: 16px;
            }
            @include media-breakpoint-up(xxxl) {
                font-size: 22px;
            }
            
        }

        .ln-icon, .mail-icon {
            height: 16px;
            width: auto;
            margin-left:8px;
            svg {
                height: 16px;
                width: auto;
            }
        }
    }
}

.team-member-image {
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: top;
}
