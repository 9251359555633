// accesssible modes styling

body.contrast {
    background-color: var(--bs-black) !important;
    color: var(--bs-white);

    .accessability-button {
        color: #fff !important;
    }

    .modal {
        color: black;
    }
    .social-media-link {
        background-color: var(--bs-white) !important;
    }

    .accordion-button {
        color: white !important;
    }

    footer {
        h2, h3, a {
            color: white !important;
        }
        .dropdown-menu a {
            color: black !important;
        }
    }

    .border-black {
        border-color: var(--bs-white) !important;
    }

    .nav-desktop-inner {
        a {
            svg {
                fill: var(--bs-white);
            }
        }
    }

    .offcanvas {
        &.offcanvas-start {
            border-right: 3px solid var(--bs-white);
        }
        &.offcanvas-end {
            border-left: 3px solid var(--bs-white);
        }
    }

    .result-type-filter-wrapper {
        .btn-black {
            @extend .btn-white;
        }
    }

    .slide {
        &.bg-white {
            color: var(--bs-black);
        }
    }

    .job-card {
        > div {
            background-color: var(--bs-white);
            color: var(--bs-black);
        }
    }

    .flip-card-front,
    .flip-card-back {
        @extend .text-black;
    }

    .filters {
        .filter-button {
            @extend .btn-outline-white;
            @extend .bg-white;
            @extend .text-black;

            &:hover,
            &:focus,
            &:active,
            &.active {
                @extend .bg-white;
                @extend .text-black;
            }
        }
    }
    .filters {
        .filter-button-style {
            @extend .btn-outline-white;
            @extend .bg-white;
            @extend .text-black;
        }
    }

    .filter-on-off i {
        color: var(--bs-black) !important;
    }
    .text-grey-dark {
        color: var(--bs-white) !important;
    }

    .bg-yellow{
        background-color: var(--bs-white) !important;
    }
    
    header .svg-maske path {
        fill: var(--bs-black);
    }

    .letter-container {
        .text-grey-light {
            opacity: 0.75;
        }
        [data-reference-letter] {
            color: var(--bs-white);

            &.active {
                color: var(--bs-white);
            }
        }
    }

    /*
    .breadcrumb-item {
        a {
            border-bottom: 1px solid var(--bs-white);
        }

        span {
            color: var(--bs-white) !important;
        }
    }
    .breadcrumb-item + .breadcrumb-item::before {
        color: var(--bs-white);
    }
    
     */

    .navigation-fixed {
        .breadcrumb-item {
            a {
                border-color: var(--bs-black);
            }
            span {
                color: var(--bs-black) !important;
            }
        }

        .breadcrumb-item + .breadcrumb-item::before {
            color: var(--bs-black);
        }
    }

    #teamFilter {
        button {
            background-color: transparent;
        }

        .btn.btn-black {
            border-color: var(--bs-white);
            background-color: var(--bs-white);
            color: var(--bs-black);
        }
    }

    .container-productarea {
        .nav-item {
            .fal {
                color: var(--bs-white) !important; // icons coloring
            }
            .nav-link {
                &.text-black {
                    color: var(--bs-white) !important; // link coloring
                }
            }
        }
    }

    #productfotosdesktop {
        background-color: white;
    }

    .btn.btn-blue {
        background-color: white;
        color: black;
        border-color: white;
    }

    .accordion-body.text-black {
        background-color: white;
    }

    .container-single-product .button-slide-down-wrapper {
        background: none; // prevent gradient
    }

    #productAccordion {
        .accordion-item {
            button.accordion-button {
                color: var(--bs-white);

                &:after {
                    background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><path d="M15.477.908,15.49,28.2,1.375,17.679s0,2.155,0,2.155l14.99,11.075L31.375,19.8V17.689L17.24,28.2,17.246.908Z" fill="white"/></svg>') !important;
                }
                &.collapsed {
                    &:after {
                        background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><path d="M15.477.908,15.49,28.2,1.375,17.679s0,2.155,0,2.155l14.99,11.075L31.375,19.8V17.689L17.24,28.2,17.246.908Z" fill="white"/></svg>') !important;
                    }
                }
            }

            .accordion-body {
                color: var(--bs-white);
            }
        }
    }

    .container-fluid-filters .filter-on-off, .container-fluid-filters .filter-button,
    .container-fluid-filters .filter-button-style {
        box-shadow: 0px 0px 15px rgb(255 255 255 / 16%);
        i {
            color: var(--bs-white);
        }
    }

    .filter-desktop .active-filter.text-black {
        color: var(--bs-white) !important;
    }

    .text-black-white {
        color: var(--bs-white) !important;
    }

    #variantsresultlist .variant {
        button.accordion-button,
        small.text-grey-dark,
        ul.list-technische-eigenschaften li span,
        h3 {
            color: var(--bs-black) !important;
        }
    }
    
}

body.grayscale {
    footer, header, #navigation,
    main img,
    main .cta-home,
    main .btn-blue,
    main .card-overview,
    main .container-video,
    main .container-productarea,
    main .container-productcategories,
    main .container-single-product,
    main .accordion,
    main .slider-neu,
    main .bg-green,
    main .bg-blue,
    main .bg-yellow,
    main .bg-bordeaux,
    main .container-cta2cols,
    main .team-member-component,
    main .bg-image-cover,
    .offerteButton,
    .offcanvas {
        filter: grayscale(100%);
    }
    scrollbar-color: var(--bs-grey-light) var(--bs-black) !important;

    &::-webkit-scrollbar-thumb { background: var(--bs-black) !important; }
    &::-webkit-scrollbar-track { background: var(--bs-grey-light) !important; }

    #variantsresultlist .variant {
        button.accordion-button,
        small.text-grey-dark,
        ul.list-technische-eigenschaften li span,
        h3 {
            color: var(--bs-black) !important;
        }
    }
}
