main, footer, header, nav, #grid {
    @include media-breakpoint-up(xxxxl) {
        .container-fluid > .row {
            margin: auto;
            max-width: 1806px;
            // max-width: 2000px;
        }
    }
}

/*
body:not(.body-image) main {
    margin-top:70px;
}
*/

main  {

    > .sprig-component > .container-fluid,
    > .container-fluid {

        //margin-top: 2rem;
        margin-bottom: 2rem;

        @include media-breakpoint-up(md) {
            //   margin-top: 3rem;
            margin-bottom: 3rem;
        }
        @include media-breakpoint-up(lg) {
            //   margin-top: 5rem;
            margin-bottom: 5rem;
        }

        .container-fluid {
            margin-bottom: 2rem;

            @include media-breakpoint-up(md) {
                margin-bottom: 3rem;
            }
            @include media-breakpoint-up(lg) {
                margin-bottom: 5rem;
            }
        }
    }

    > .container-fluid.bg-yellow,
    > .container-fluid.bg-green,
    > .container-fluid.bg-blue,
    > .container-fluid.bg-bordeaux {
        margin: 0;
        padding-top: 2rem;
        padding-bottom: 2rem;

        @include media-breakpoint-up(md) {
            padding-top: 3rem;
            padding-bottom: 3rem;
        }

        @include media-breakpoint-up(lg) {
            padding-top: 5rem;
            padding-bottom: 5rem;
        }
    }

    .container-fluid.container-project-overviews .items .col,
    .container-fluid.container-product-overviews .items .col,
    .container-fluid.container-overviewmaterials .items .col {
        margin-bottom: 2rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 3rem;
        }
        @include media-breakpoint-up(lg) {
            margin-bottom: 5rem;
        }
    }
    .container-fluid.container-project-overviews + .container-fluid,
    .container-fluid.container-product-overviews + .container-fluid,
    .container-fluid.container-overviewmaterials + .container-fluid,
    .container-dynamic-headline + .container-fluid {
        margin-top:0;
    }

    .container-headline {
        padding-bottom: 0;
        margin-bottom: 5px;
    }
    .container-text {
        margin-bottom: 0.5rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 1.5rem;
        }
        @include media-breakpoint-up(lg) {
            margin-bottom: 3.5rem;
        }
        p, ul {
            margin-bottom: 1.5rem;
        }
        ul li {
            margin-bottom: 0;
        }
    }
    .container-headline + .container-text {
        margin-top: 0.5rem;
        padding-top: 0;
    }
    .container-multi-col-content {
        .container-multi-col-content-block {
            margin-bottom: 1.5rem;
        }
        h2, .h2, h3, .h3, p {
            margin-bottom: 1.5rem;
        }
        q {
            //font-size: 36px;
            //line-height: 40px;
            margin-bottom: 1.5rem;
            font-size: 1.5rem;
            line-height: 1.8rem;
            quotes: '«' '»';
        }
        .person-2-col-wrapper {
            .bg-image-cover {
                width: 100%;
                @include media-breakpoint-up(md) {
                    width: calc((100% / 3 * 2) - 5px);
                }
                @include media-breakpoint-up(xl) {
                    width: calc((100% / 3 * 2) - 12px);
                }
                @include media-breakpoint-up(xxl) {
                    width: calc((100% / 3 * 2) - 17px);
                }
            }
        }
        .zitat-wrapper p {
            margin-bottom: 0;
        }
        .container-multi-col-content-block:last-of-type {
            margin-bottom: 0;
        }
    }
}


main > .container-fluid.container-masonry-1 {
    margin-top:40px;
    @include media-breakpoint-up(sm) {
        margin-top: calc($spa-sm * 1.5 + 49px);
    }
    @include media-breakpoint-up(md) {
        margin-top: calc($spa-md * 1.5 + 49px);
    }
    @include media-breakpoint-up(xxl) {
        margin-top: calc($spa-xxl * 1 + 49px);
    }
    @include media-breakpoint-up(xxxl) {
        margin-top: calc($spa-xxxl * 1 + 49px);
    }
}


/* yellow scrollbar styling */
.scrollbar-styling {
    scrollbar-color: var(--bs-grey-light) var(--bs-yellow);

    &::-webkit-scrollbar {
        width: 12px;
        height: 12px;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--bs-yellow);
    }
    &::-webkit-scrollbar-track {
        background: var(--bs-grey-light);
    }
}

.color-blue {
    color: var(--bs-blue);
}


.headline-teaser {
    h2 {
        margin-bottom: calc($spa);
        @include media-breakpoint-up(sm) {
            margin-bottom: calc($spa-sm);
        }
        @include media-breakpoint-up(md) {
            margin-bottom: calc($spa-md);
        }
        @include media-breakpoint-up(lg) {
            margin-bottom: calc($spa-lg);
        }
        @include media-breakpoint-up(xl) {
            margin-bottom: calc($spa-xl);
        }
        @include media-breakpoint-up(xxl) {
            margin-bottom: calc($spa-xxl);
        }
        @include media-breakpoint-up(xxxl) {
            margin-bottom: calc($spa-xxxl);
        }
        @include media-breakpoint-up(xxxxl) {
            margin-bottom: calc($spa-xxxxl);
        }
    }
    p.lead {
        font-family: 'Bradford', serif;
    }

}

.container-multi-col-content .container-multi-col-content-block h3,
.container-headline h2,
.container-headline .h3,
.headline-teaser p.lead {
    font-family: 'Bradford', serif;
    font-size: 17.5px;
    line-height: 24px;
    @include media-breakpoint-up(sm) {
        font-size: 23px;
        line-height: 30px;
    }
    @include media-breakpoint-up(lg) {
        font-size: 22.5px;
        line-height: 28px;
    }
    @include media-breakpoint-up(xl) {
        font-size: 23px;
        line-height: 30px;
    }
    @include media-breakpoint-up(xxxl) {
        font-size: 27px;
        line-height: 34px;
    }
}

.headline-teaser p.lead.productLead,
#variantsresultlist h3 {
    font-size: 17px;
    @include media-breakpoint-up(lg) {
        font-size: 22px;
        line-height: 30px;
    }
}
.container-multi-col-content-block-handle-headline ~ .container-multi-col-content-block-handle-text {
    margin-top: -1rem;
}
.container-multi-col-content {
    .accordion-item {
        // margin-bottom: 1.5rem;
    }
}


.container-multi-col-content .colRight div {
    margin-bottom: 1.5rem;
}
.container-multi-col-content .colRight div:last-of-type {
    margin-bottom: 0;
}


.container-fluid.container-image-caption + .container-text {
    margin-top: calc(0px - 2rem + 24px);
    @include media-breakpoint-up(md) {
        margin-top: calc(0px - 3rem + 24px);
    }
    @include media-breakpoint-up(lg) {
        margin-top: calc(0px - 5rem + 40px);
    }
}

.body-project main .container-cta2cols .container-fluid,
.body-default main .container-cta2cols .container-fluid {
    margin-bottom: 2rem;
    @include media-breakpoint-up(md) {
        margin-bottom: 3rem;
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: 5rem;
    }
}

.paddingTop img {
    @include media-breakpoint-up(lg) {
        margin-top: 40px;
    }
    @include media-breakpoint-up(xl) {
        margin-top: 42px;
    }
    @include media-breakpoint-up(xxl) {
        margin-top: 44px;
    }
    @include media-breakpoint-up(xxxl) {
        margin-top: 48px;
    }
}
