.cta-home {

    color: var(--bs-black);

    @include media-breakpoint-up(xxxl) {
        .fix2000 {
            margin: auto!important;
            max-width: 1806px;
        }
        .px-xxxxl-0 {
            padding-left: 0!important;
            padding-right: 0!important;
        }
    }

    .cta-image {
        @include media-breakpoint-up(lg) {
            height: 100%;
            object-fit: cover;
            position: absolute;
            width: 50%;
        }
    }

    .cta-keyword {
        font-size: 11px;
        @include media-breakpoint-up(xxxl) {
            font-size: 13px;
        }
    }
    h2 {
        font-size: 22px;
        line-height: 28px;
        @include media-breakpoint-up(sm) {
            font-size: 26px;
            line-height: 32px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 35px;
            line-height: 45px;
        }
        @include media-breakpoint-up(xl) {
            font-size: 40px;
            line-height: 52px;
        }
        @include media-breakpoint-up(xxl) {
            font-size: 45px;
            line-height: 55px;
        }
        @include media-breakpoint-up(xxxl) {
            font-size: 50px;
            line-height: 60px;
        }
    }
    .cta-text {
        font-size: 14px;
        line-height: 20px;
        @include media-breakpoint-up(sm) {
            font-size: 14px;
            line-height: 22px;
        }
        @include media-breakpoint-up(xl) {
            font-size: 16px;
            line-height: 25px;
        }
    }
}


.cta-home.cta-home-tlbr {
    .cta-image, .wrapper-maske {
        @include media-breakpoint-up(lg) {
            left: 50%;
        }
    }
}


.cta-productlist {
    color: var(--bs-black);
}
.cta-home.bg-green,
.cta-home.bg-blue,
.cta-home.bg-bordeaux,
.cta-productlist.bg-green,
.cta-productlist.bg-blue,
.cta-productlist.bg-bordeaux {
    color: var(--bs-white);
}

.color-inherit {
    color: inherit;
}
.fill-yellow        svg { fill: var(--bs-yellow); }
.fill-green         svg { fill: var(--bs-green); }
.fill-bordeaux      svg { fill: var(--bs-bordeaux); }
.fill-blue          svg { fill: var(--bs-blue); }
.fill-white         svg { fill: var(--bs-white); }
.fill-grey-light    svg { fill: var(--bs-grey-light); }


.container-cta2cols {
    
    @include media-breakpoint-up(lg) {
        font-size: 35px;
        line-height: 45px;
    }
    @include media-breakpoint-up(xl) {
        font-size: 40px;
        line-height: 52px;
    }
    @include media-breakpoint-up(xxl) {
        font-size: 45px;
        line-height: 55px;
    }
    @include media-breakpoint-up(xxxl) {
        font-size: 50px;
        line-height: 60px;
    }

    .cta-text {
        font-size: 16px;
        line-height: 21px;
    }
    .bg-image-contain {
        background-position: right top;
    }

    .container-cta2col {
        padding: calc(16px);

        @include media-breakpoint-up(sm) {
            padding: calc(30px);
        }
        @include media-breakpoint-up(md) {
            padding: calc(48px);
        }
        @include media-breakpoint-up(lg) {
            padding-left: 0;
            padding-right: 0;
        }

        padding-top: 70px;
        @include media-breakpoint-up(lg) {
            padding-top: 120px;
        }
    }

    .container-cta2col.comes-with-image {
        padding-top: 100px;
        @include media-breakpoint-up(lg) {
            padding-top: 200px;
        }
    }

    @include media-breakpoint-up(xxxxl) {
        .container-cta2col:first-child {
            padding-left: calc((100vw - 2000px) / 2);
        }
        .container-cta2col:last-child {
            padding-right: calc((100vw - 2000px) / 2);
        }
    }

    color: var(--bs-black);

    .color-yellow       { background-color: var(--bs-yellow); }
    .color-green        { background-color: var(--bs-green); }
    .color-bordeaux     { background-color: var(--bs-bordeaux); }
    .color-blue         { background-color: var(--bs-blue); }
    .color-white        { background-color: var(--bs-white); }
    .color-grey-light   { background-color: var(--bs-grey-light); }

}

.container-cta2col.color-green,
.container-cta2col.color-blue,
.container-cta2col.color-bordeaux {
    a, p, h2 {
        color: var(--bs-white);
    }
    .bg-white {
        color: var(--bs-black);
    }
}

.container-fluid.container-life-cycle-image.bg-green + .container-fluid,
.container-fluid.container-life-cycle-image.bg-yellow + .container-fluid,
.container-fluid.container-life-cycle-image.bg-bordeaux + .container-fluid {
    margin-top: 2rem;
}

body.filteractive .cta-home {
    display: none;
}

.cta-home2 {
    margin-left: calc(8px - 16px);
    margin-right:  calc(8px - 16px);
    .cta-home-background {
        width: calc(100vw - 10px);
        @include media-breakpoint-up(xxxxl) {
            width: 1900px;
        }
    }

    @include media-breakpoint-up(sm) {
        margin-left: calc(10px - 30px);
        margin-right:  calc(10px - 30px);
    }
    @include media-breakpoint-up(md) {
        margin-left: calc(15px - 48px);
        margin-right:  calc(15px - 48px);
    }
    @include media-breakpoint-up(lg) {
        margin-left: calc(15px - 32px);
        margin-right:  calc(15px - 32px);
    }
    @include media-breakpoint-up(xl) {
        margin-left: calc(15px - 64px);
        margin-right: calc(15px - 64px);
    }
    @include media-breakpoint-up(xxl) {
        margin-left: calc(20px - 80px);
        margin-right: calc(20px - 80px);
    }
    @include media-breakpoint-up(xxxl) {
        margin-left: calc(25px - 92px);
        margin-right: calc(25px - 92px);
    }
    @include media-breakpoint-up(xxxxl) {
        margin-left: calc(44px - 92px);
        margin-right: calc(44px - 92px);
    }

    /*
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
    xxxl: 1800px,
    xxxxl: 2000px,
    */

}
