.flip-card-group {
    .flip-card {
        perspective: 40rem;
        cursor: pointer;

        &.active {
            .flip-card-body {
                transform: rotateY(180deg);
            }
        }

        .flip-card-body {
            display: grid;
            transition: transform 0.8s;
            transform-style: preserve-3d;
            -webkit-transform-style: preserve-3d;

            .flip-card-front,
            .flip-card-back {
                backface-visibility: hidden;
                -webkit-backface-visibility: hidden; /* safari fix */
                //mix-blend-mode: multiply; // Preview both faces
                grid-area: 1/1;

                .flip-card-text p {
                    font-size: $font-size-base * 1.5;
                    line-height: $line-height-base;
                    margin-bottom: $spacer * 4;
                }
            }

            .flip-card-front {
                transform: rotateX(0deg);
            }
            .flip-card-back {
                inset: 0;
                overflow-y: auto;
                overflow-x: hidden;
                transform: rotateY(-180deg);

                //overflow scroll styling
                &::-webkit-scrollbar {
                    width: 10px;
                }

                &::-webkit-scrollbar-track {
                    background: var(--bs-yellow);
                }

                &::-webkit-scrollbar-thumb {
                    background-color: var(--bs-grey-light);
                    border-radius: 0;
                    border: 0;
                }
            }
        }
    }
}
