// teaser-slider //
.teaser-slider {
    button {
        border: 1px solid var(--bs-black);
        width: 32px;
        height: 32px;
        border-radius: 100%;
    }

    button:disabled,
    button[disabled] {
        display: none;
    }
}

.lifecyclecare-slider {
    a {
        text-align: left;
    }

    .icon, svg {
        // height: 36px;
        height: 64px;
        width: auto;
        margin-bottom: 30px;
    }
}

.image-slider {
    .slide {
        height: 400px;
        @include media-breakpoint-up(md) { //768px
            height: 500px;
        }
        @include media-breakpoint-up(lg) { //992px
            height: 600px;
        }
        @include media-breakpoint-up(xl) { //1280px
            // height: 791px;
            height: 600px;
        }
    }
    .slide img {
        max-height: 400px;
        @include media-breakpoint-up(md) { //768px
            max-height: 500px;
        }
        @include media-breakpoint-up(lg) { //992px
            max-height: 600px;
        }
        @include media-breakpoint-up(xl) { //1280px
            // max-height: 791px;
            max-height: 600px;
        }
    }

    .splide__slide img {
        width: 100%;
        height: 100% !important;
        object-fit: cover;
    }

    .splide__arrows {
        position: absolute;
        top: calc(50% - 14px);
        height: 28px;
        z-index: 1;
        width: 100%;
        .splide__arrow--prev {
            position: absolute;
            left: 32px;
        }
        .splide__arrow--next {
            position: absolute;
            right: 32px;
        }
    }
}

.image-slider-products {
    .splide__arrows {
        position: absolute;
        top: calc(50% - 14px);
        // height: 28px;
        z-index: 1;
        width: 100%;
        .splide__arrow--prev {
            position: absolute;
            left: 32px;
        }
        .splide__arrow--next {
            position: absolute;
            right: 32px;
        }
    }
}
.produktbild-wrapper .image-slider, .image-slider-products {
    .slide,
    .slide img {
        height: auto;
        max-height: none;
    }
}

/* slider prev/next buttons styling */
.splide__arrow {
    transition: background-color 0.2s ease-in-out;
    background-color: var(--bs-white);

    &:hover {
        background-color: var(--bs-grey-light);
        box-shadow: 0 .5rem 1rem rgba(var(--bs-black), .4);
    }
}
.splide--track {
    direction: rtl;
}

.splide__slide {
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
}

.arrow-slider {
    .splide__arrows {
        position: absolute;
        top: calc(50% - 14px);
        height: 28px;
        z-index: 1;
        width: 100%;
        .splide__arrow--prev {
            position: absolute;
            left: 32px;
        }
        .splide__arrow--next {
            position: absolute;
            right: 32px;
        }
    }
}
