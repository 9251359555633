.container-product-overviews {
    svg {
        height: 100%;
        width: 100%;
        position: absolute;
    }
}

.product-image-border {
    border-top: 16px solid var(--bs-yellow);
}

.offerteButton {
    top: 80px;
    z-index: 1001;
}

.product-link-category, .product-link-family, .product-link-product {
    .product-image-border {
        border-top: none;
    }
}
.product-link-product {
    span {
        font-size: 16px;
    }
}

.body-index-products main > .container-productarea:first-child {
    margin-top: 32px;
}

.container-productarea {
    .nav-item {
        .fa-minus, .catimages { display: none; }
    }
    .nav-item.active {
        .fa-plus { display: none; }
        .fa-minus, .catimages { display: block; }
    }
    .nav-item:hover {
        cursor: pointer;
        a {
            text-decoration: none;
        }
    }
    .catimages {
        display: none;
    }
    .catimages.active {
        display: block;
    }


    .nav-productarea {
        .nav-item {
            .nav-item-nav {
                a, span {
                    color: var(--bs-black);
                }
            }
        }
    }
    .nav-productarea.nav-productarea-color-yellow {
        .nav-item.active {
            .nav-item-nav {
                a, span {
                    color: var(--bs-yellow) !important;
                    font-weight: bold;
                }
            }
        }
    }
    .nav-productarea.nav-productarea-color-green {
        .nav-item.active {
            .nav-item-nav {
                a, span {
                    color: var(--bs-green) !important;
                    font-weight: bold;
                }
            }
        }
    }
    .nav-productarea-color-bordeaux {
        .nav-item.active {
            .nav-item-nav {
                a, span {
                    color: var(--bs-bordeaux) !important;
                    font-weight: bold;
                }
            }
        }
    }

}


@include media-breakpoint-up(lg) {
    .min-height-492 {
        min-height: 492px;
    }
}

.toasts .toast {
    border: none;
    width: auto;
    p.keyword {
        color: var(--bs-white);
    }
    .toast-header {
        color: var(--bs-white);
        background-color: transparent;
    }
    .btn-close {
        opacity:1;
    }
}
.toasts .toast.show {
    display: inline-block;
}

[data-productrange='color-yellow'] .toasts .toast {
    background-color: var(--bs-yellow);
    p.keyword {
        color: var(--bs-black);
    }
    .btn-close-white {
        filter: none;
    }
}
[data-productrange='color-green'] .toasts .toast {
    background-color: var(--bs-green);
}
[data-productrange='color-bordeaux'] .toasts .toast {
    background-color: var(--bs-bordeaux);
}

[data-productrange='color-yellow'] .active-filter-span {
    background-color: var(--bs-yellow);
    color: var(--bs-black);
    .btn-close-white {
        filter: none;
    }
}
[data-productrange='color-green'] .active-filter-span {
    background-color: var(--bs-green);
    color: var(--bs-white);
}
[data-productrange='color-bordeaux'] .active-filter-span {
    background-color: var(--bs-bordeaux);
    color: var(--bs-white);
}


.container-productcategories .toasts {
    label {
        width: 9px;
        height: 9px;
        margin: 0;
        padding: 0;
        margin-left: 4px;
        display: inline;
    }
}

.produktbild-wrapper img.mb-spa:last-child,
.produktbild-wrapper img.mb-spa:last-of-type {
    margin-bottom: 5px;
}

.produkt-icons-svg {
    height: 24px;
    width: 24px;
    margin-right: 20px;
    @include media-breakpoint-up(lg) {
        margin-right: 50px;
    }
}
