body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-family: 'Helvetica Now', sans-serif;
    font-style: normal;
    font-weight: 400;

    //line-height: 150%;
    //font-size: $font-size-base;
    //letter-spacing: 0.3px;

    font-size: 14px;
    line-height: calc(22 / 14);

    
    color: var(--bs-black);

    b, strong {
        font-weight: 900;
    }
}

h1, .h1, h1 p, .h1 p {
    font-family: 'Bradford', serif;
    font-size: $font-size-base * 2.5;

    @include media-breakpoint-up(md) {
        font-size: $font-size-base * 6.8125;
    }
    @include media-breakpoint-up(xxl) {
        font-size: $font-size-base * 8.1875;
    }
}
h2, .h2, .h2 p {
    font-family: 'Bradford', serif;
    font-weight: normal;
    font-size: 28px;
    line-height: 33px;
    @include media-breakpoint-up(sm) {
        font-size: 26px;
        line-height: 32px;
    }
    @include media-breakpoint-up(lg) {
        font-size: 30px;
        line-height: 40px;
    }
    @include media-breakpoint-up(xl) {
        font-size: 35px;
        line-height: 45px;
    }
    @include media-breakpoint-up(xxxl) {
        font-size: 42px;
        line-height: 55px;
    }

}

h3, .h3 {
    font-family: 'Bradford', serif;
    font-size: $font-size-base * 1.625;
    //margin-bottom: $spacer * 6;

    @include media-breakpoint-up(md) {
        font-size: $font-size-base * 1.625;
        //margin-bottom: $spacer * 1.33;
    }

    @include media-breakpoint-up(xxl) {
        font-size: $font-size-base * 2.1875;
        //margin-bottom: $spacer * 6;
    }
}

.lead {
    font-size: 17px;
    line-height: 24px;

    @include media-breakpoint-up(md) {
        font-size: $font-size-base * 1.45;
        line-height: 1.35;
    }

    @include media-breakpoint-up(xxl) {
        font-size: $font-size-base * 1.8;
        line-height: 1.22;
    }
    
}

p,
ul li {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    @include media-breakpoint-up(sm) {
        font-size: 16px;
        line-height: 25px;
    }
    @include media-breakpoint-up(lg) {
        font-size: 14px;
        line-height: 22px;
    }
    @include media-breakpoint-up(xl) {
        font-size: 16px;
        line-height: 25px;
    }
    @include media-breakpoint-up(xxxl) {
        font-size: 18px;
        line-height: 28px;
    }
}

@include media-breakpoint-up(xxl) {
    .fs-max-16px {
        font-size: 16px;
        line-height: 25px;
    }
}

a, a:hover {
    color: inherit;
    text-decoration: underline;
}

.keyword {
    color: var(--bs-grey-dark);
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 2.5px;
    margin-bottom: $spacer;

    @include media-breakpoint-up(xxxl) {
        font-size: 13px;
    }
}
.container-masonry {
    .keyword {
        margin-bottom: 0;
    }
    @include media-breakpoint-down(lg) {
        h2.h3 {
            font-size: 1.22rem;
        }
        .p-teaser {
            font-size: 0.95rem;
            line-height: 1.5;
        }
    }
}

// font family utility classes
.font-family-sans-serif {
    font-family: 'Helvetica Now', sans-serif !important;
}
.font-family-serif {
    font-family: 'Bradford', serif !important;
}

.breadcrumb-item {
    letter-spacing: 2px;
}

small p {
    font-size: 0.875em;
}
.hyphens {
    hyphens: auto;
    hyphenate-limit-chars: 6 3 2;
}

[data-reference-letter] {
    color: var(--bs-black);

    &.active {
        color: var(--bs-blue);
    }
}

.italic2coloryellow em {
    color: var(--bs-yellow);
}
.italic2colorgreen em {
    color: var(--bs-green);
}
.italic2colorbordeaux em {
    color: var(--bs-bordeaux);
}
.italic2colorblue em {
    color: var(--bs-blue);
}

.lh-2 {
    line-height: 2;
}
.fs-14px {
    font-size: 14px;
}

.fs-14px-16px {
    font-size: 14px;
    line-height: 20px;
    @include media-breakpoint-up(lg) {
        font-size: 16px;
        line-height: 25px;
    }
}
.fs-17px-22px {
    font-size: 17px;
    line-height: 24px;
    @include media-breakpoint-up(lg) {
        font-size: 22px;
        line-height: 30px;
    }
}



