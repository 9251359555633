.p-spa-0-5 {
    padding: calc($spa / 2);
    @include media-breakpoint-up(sm) {
        padding: calc($spa-sm / 2);
    }
    @include media-breakpoint-up(md) {
        padding: calc($spa-md / 2);
    }
    @include media-breakpoint-up(lg) {
        padding: calc($spa-lg / 2);
    }
    @include media-breakpoint-up(xl) {
        padding: calc($spa-xl / 2);
    }
    @include media-breakpoint-up(xxl) {
        padding: calc($spa-xxl / 2);
    }
    @include media-breakpoint-up(xxxl) {
        padding: calc($spa-xxxl / 2);
    }
    @include media-breakpoint-up(xxxxl) {
        padding: calc($spa-xxxxl / 2);
    }
}
.p-spa-md-1 {
    @include media-breakpoint-up(md) {
        padding: calc($spa-md);
    }
    @include media-breakpoint-up(lg) {
        padding: calc($spa-lg);
    }
    @include media-breakpoint-up(xl) {
        padding: calc($spa-xl);
    }
    @include media-breakpoint-up(xxl) {
        padding: calc($spa-xxl);
    }
    @include media-breakpoint-up(xxxl) {
        padding: calc($spa-xxxl);
    }
    @include media-breakpoint-up(xxxxl) {
        padding: calc($spa-xxxxl);
    }
}
.ms-spa-md-1 {
    @include media-breakpoint-up(md) {
        margin-left: calc($spa-md);
    }
    @include media-breakpoint-up(lg) {
        margin-left: calc($spa-lg);
    }
    @include media-breakpoint-up(xl) {
        margin-left: calc($spa-xl);
    }
    @include media-breakpoint-up(xxl) {
        margin-left: calc($spa-xxl);
    }
    @include media-breakpoint-up(xxxl) {
        margin-left: calc($spa-xxxl);
    }
    @include media-breakpoint-up(xxxxl) {
        margin-left: calc($spa-xxxxl);
    }
}

main .container-fluid .mb-spa-0-5,
.mb-spa-0-5 {
    margin-bottom: calc($spa / 2);
    @include media-breakpoint-up(sm) {
        margin-bottom: calc($spa-sm / 2);
    }
    @include media-breakpoint-up(md) {
        margin-bottom: calc($spa-md / 2);
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: calc($spa-lg / 2);
    }
    @include media-breakpoint-up(xl) {
        margin-bottom: calc($spa-xl / 2);
    }
    @include media-breakpoint-up(xxl) {
        margin-bottom: calc($spa-xxl / 2);
    }
    @include media-breakpoint-up(xxxl) {
        margin-bottom: calc($spa-xxxl / 2);
    }
    @include media-breakpoint-up(xxxxl) {
        margin-bottom: calc($spa-xxxxl / 2);
    }
}
main .container-fluid .mb-spa,
main .container-fluid .mb-spa-1,
.mb-spa,
.mb-spa-1 {
    margin-bottom: calc($spa);
    @include media-breakpoint-up(sm) {
        margin-bottom: calc($spa-sm);
    }
    @include media-breakpoint-up(md) {
        margin-bottom: calc($spa-md);
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: calc($spa-lg);
    }
    @include media-breakpoint-up(xl) {
        margin-bottom: calc($spa-xl);
    }
    @include media-breakpoint-up(xxl) {
        margin-bottom: calc($spa-xxl);
    }
    @include media-breakpoint-up(xxxl) {
        margin-bottom: calc($spa-xxxl);
    }
    @include media-breakpoint-up(xxxxl) {
        margin-bottom: calc($spa-xxxxl);
    }
}
main .container-fluid .mb-spa-2,
.mb-spa-2 {
    margin-bottom: calc($spa * 2);
    @include media-breakpoint-up(sm) {
        margin-bottom: calc($spa-sm * 2);
    }
    @include media-breakpoint-up(md) {
        margin-bottom: calc($spa-md * 2);
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: calc($spa-lg * 2);
    }
    @include media-breakpoint-up(xl) {
        margin-bottom: calc($spa-xl * 2);
    }
    @include media-breakpoint-up(xxl) {
        margin-bottom: calc($spa-xxl * 2);
    }
    @include media-breakpoint-up(xxxl) {
        margin-bottom: calc($spa-xxxl * 2);
    }
    @include media-breakpoint-up(xxxxl) {
        margin-bottom: calc($spa-xxxxl * 2);
    }
}
main .container-fluid .mb-spa-3,
.mb-spa-3 {
    margin-bottom: calc($spa * 3);
    @include media-breakpoint-up(sm) {
        margin-bottom: calc($spa-sm * 3);
    }
    @include media-breakpoint-up(md) {
        margin-bottom: calc($spa-md * 3);
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: calc($spa-lg * 3);
    }
    @include media-breakpoint-up(xl) {
        margin-bottom: calc($spa-xl * 3);
    }
    @include media-breakpoint-up(xxl) {
        margin-bottom: calc($spa-xxl * 3);
    }
    @include media-breakpoint-up(xxxl) {
        margin-bottom: calc($spa-xxxl * 3);
    }
    @include media-breakpoint-up(xxxxl) {
        margin-bottom: calc($spa-xxxxl * 3);
    }
}

main .container-fluid .mb-spa-lg,
main .container-fluid .mb-spa-lg-1,
.mb-spa-lg-1,
.mb-spa-lg-1 {
    @include media-breakpoint-up(lg) {
        margin-bottom: calc($spa-lg);
    }
    @include media-breakpoint-up(xl) {
        margin-bottom: calc($spa-xl);
    }
    @include media-breakpoint-up(xxl) {
        margin-bottom: calc($spa-xxl);
    }
    @include media-breakpoint-up(xxxl) {
        margin-bottom: calc($spa-xxxl);
    }
    @include media-breakpoint-up(xxxxl) {
        margin-bottom: calc($spa-xxxxl);
    }
}




main .container-fluid .mt-spa-2,
.mt-spa-2 {
    margin-top: calc($spa * 2);
    @include media-breakpoint-up(sm) {
        margin-top: calc($spa-sm * 2);
    }
    @include media-breakpoint-up(md) {
        margin-top: calc($spa-md * 2);
    }
    @include media-breakpoint-up(lg) {
        margin-top: calc($spa-lg * 2);
    }
    @include media-breakpoint-up(xl) {
        margin-top: calc($spa-xl * 2);
    }
    @include media-breakpoint-up(xxl) {
        margin-top: calc($spa-xxl * 2);
    }
    @include media-breakpoint-up(xxxl) {
        margin-top: calc($spa-xxxl * 2);
    }
    @include media-breakpoint-up(xxxxl) {
        margin-top: calc($spa-xxxxl * 2);
    }
}

/*
body {
    background-color: yellow;

    @include media-breakpoint-up(md) {
        background-color: orange;
    }
    @include media-breakpoint-up(lg) {
        background-color: red;
    }
}
*/
