
@font-face {
    font-family: 'Bradford';
    src:
        url('../../fonts/Bradford/BradfordLL-Regular.otf') format('opentype'),
        url('../../fonts/Bradford/BradfordLLTT-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    font-feature-settings: "ss01";
}

@font-face {
    font-family: 'Bradford';
    src:
        url('../../fonts/Bradford/BradfordLL-Italic.otf') format('opentype'),
        url('../../fonts/Bradford/BradfordLLTT-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
    font-feature-settings: "ss01";
}

@font-face {
    font-family: 'Helvetica Now';
    src: url('../../fonts/HelveticaNow/HelveticaNowDisplayMdIt.otf') format('opentype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Now';
    src: url('../../fonts/HelveticaNow/HelveticaNowDisplayMedium.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Now';
    src: url('../../fonts/HelveticaNow/HelveticaNowDisplayXBlkIt.otf') format('opentype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Now';
    src: url('../../fonts/HelveticaNow/HelveticaNowDisplayXBlack.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
