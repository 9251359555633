[data-productrange="color-blue"] {
    scrollbar-color: var(--bs-grey-light) var(--bs-blue);
    &::-webkit-scrollbar { width: 10px; height: 10px; }
    &::-webkit-scrollbar-thumb { background: var(--bs-blue); }
    &::-webkit-scrollbar-track { background: var(--bs-grey-light); }

    .offcanvas-body,
    .row-productfamily {
        scrollbar-color: var(--bs-grey-light) var(--bs-blue);
        &::-webkit-scrollbar { width: 10px; height: 10px; }
        &::-webkit-scrollbar-thumb { background: var(--bs-blue); }
        &::-webkit-scrollbar-track { background: var(--bs-grey-light); }
    }
}
[data-productrange="color-yellow"] {
    scrollbar-color: var(--bs-grey-light) var(--bs-yellow);
    &::-webkit-scrollbar { width: 10px; height: 10px; }
    &::-webkit-scrollbar-thumb { background: var(--bs-yellow); }
    &::-webkit-scrollbar-track { background: var(--bs-grey-light); }

    .offcanvas-body,
    .row-productfamily {
        scrollbar-color: var(--bs-grey-light) var(--bs-yellow);
        &::-webkit-scrollbar { width: 10px; height: 10px; }
        &::-webkit-scrollbar-thumb { background: var(--bs-yellow); }
        &::-webkit-scrollbar-track { background: var(--bs-grey-light); }
    }
}
[data-productrange="color-green"] {
    scrollbar-color: var(--bs-grey-light) var(--bs-green);
    &::-webkit-scrollbar { width: 10px; height: 10px; }
    &::-webkit-scrollbar-thumb { background: var(--bs-green); }
    &::-webkit-scrollbar-track { background: var(--bs-grey-light); }

    .offcanvas-body,
    .row-productfamily {
        scrollbar-color: var(--bs-grey-light) var(--bs-green);
        &::-webkit-scrollbar { width: 10px; height: 10px; }
        &::-webkit-scrollbar-thumb { background: var(--bs-green); }
        &::-webkit-scrollbar-track { background: var(--bs-grey-light); }
    }
}
[data-productrange="color-bordeaux"] {
    scrollbar-color: var(--bs-grey-light) var(--bs-bordeaux);
    &::-webkit-scrollbar { width: 10px; height: 10px; }
    &::-webkit-scrollbar-thumb { background: var(--bs-bordeaux); }
    &::-webkit-scrollbar-track { background: var(--bs-grey-light); }

    .offcanvas-body,
    .row-productfamily {
        scrollbar-color: var(--bs-grey-light) var(--bs-bordeaux);
        &::-webkit-scrollbar { width: 10px; height: 10px; }
        &::-webkit-scrollbar-thumb { background: var(--bs-bordeaux); }
        &::-webkit-scrollbar-track { background: var(--bs-grey-light); }
    }
}
