.container-video {

    .video-container {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
    }
    .video-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    figcaption {
        font-size: 16px;
        color: var(--bs-grey-dark);
        margin-top: 10px;
    }
}
