.breadcrumb{

    .breadcrumb-item, .breadcrumb-item.active {
        font-size: 9px;
        line-height: 15px;
        color: black;
        @include media-breakpoint-up(lg) {
            font-size: 11px;
            line-height: 17px;
        }
        @include media-breakpoint-up(xxxl) {
            font-size: 13px;
            line-height: 20px;
        }
    }

    .truncate {
        width: 50vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .dots {
        display: none;
    }
}

@include media-breakpoint-up(lg) {
    nav.breadcrumb-nav {
        max-width: calc(100% - 80px);
    }
}
