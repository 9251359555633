.card-overview.img-overview {

    .keyword-overview {
        color: var(--bs-grey-dark);
        letter-spacing: 2.5px;
        text-transform: uppercase;
        font-size: 11px;
        margin-bottom: 11px;
        margin-bottom: 0;
        @include media-breakpoint-up(sm) {
            margin-bottom: 5px;
        }
        line-height: 24px;
        @include media-breakpoint-up(xxxl) {
            font-size: 13px;
        }
    }
    .title-overview {

        font-family: 'Helvetica Now', sans-serif;
        font-style: normal;
        font-weight: 400;

        margin-bottom: 11px;
        margin-bottom: 0;
        @include media-breakpoint-up(sm) {
            margin-bottom: 5px;
        }

        font-size: 17px;
        line-height: 1.4; // 24px
        @include media-breakpoint-up(sm) {
            font-size: 22px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 20px;
            line-height: 30px;
        }
        @include media-breakpoint-up(xl) {
            font-size: 22px;
        }
        @include media-breakpoint-up(xxxl) {
            font-size: 26px;
            line-height: 34px;
        }
        @include media-breakpoint-up(xxxxl) {
            font-size: 30px;
            line-height: 38px;
        }
    }
    .teaser-overview {
        line-height: 1;
        margin-bottom: 11px;
        margin-bottom: 0;
        @include media-breakpoint-up(sm) {
            margin-bottom: 5px;
        }

        font-size: 14px;
        line-height: 1.4; // 20px
        @include media-breakpoint-up(md) {
            font-size: 16px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 14px;
        }
        @include media-breakpoint-up(xl) {
            font-size: 16px;
        }
        @include media-breakpoint-up(xxxl) {
            font-size: 18px;
        }

    }

}

.card-overview.text-overview {

    background-color: var(--bs-blue);

    div {
        padding: 20px;
        @include media-breakpoint-up(md) {
            padding: 30px;
        }
        @include media-breakpoint-up(xxl) {
            padding: 20px;
        }
    }

    p.article-keyword {
        font-size: 14px;
        @include media-breakpoint-up(sm) {
            font-size: 16px;
        }
        @include media-breakpoint-up(xxxl) {
            font-size: 22px;
        }
    }

    .h2.article-headline {
        margin-bottom: 0;
        font-size: 22px;
        line-height: 1.3;
        line-height: 28px;
        @include media-breakpoint-up(md) {
            font-size: 26px;
            line-height: 34px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 22px;
            line-height: 28px;
        }
        @include media-breakpoint-up(xl) {
            font-size: 26px;
            line-height: 34px;
        }
        @include media-breakpoint-up(xxxl) {
            font-size: 32px;
            line-height: 43px;
        }
    }
    
    p {
        color: var(--bs-white);
    }

}
@include media-breakpoint-up(lg) {
    .colmsnry.col-lg-4 .card-overview.text-overview {
        aspect-ratio: 16/9;
    }
}

.card-overview.text-overview-sectionUs {
    background-color: var(--bs-grey-light);
    border: 5px solid var(--bs-blue);
    p {
        color: var(--bs-blue);
    }
}

/* format 5:6 - nicht höher als 16:9 */
@include media-breakpoint-up(lg) {
    .card-overview.img-overview.layout-format-5-6 {
        img {
            height: calc((((100vw - 64px - 10px) / 2) - 15px) * 9 / 16);
            @include media-breakpoint-up(xl) {
                height: calc((((100vw - 128px - 10px) / 2) - 15px) * 9 / 16);
            }
            @include media-breakpoint-up(xxl) {
                height: calc((((100vw - 160px - 10px) / 2) - 20px) * 9 / 16);
            }
            @include media-breakpoint-up(xxxl) {
                height: calc((((100vw - 184px - 10px) / 2) - 25px) * 9 / 16);
            }
            @include media-breakpoint-up(xxxxl) {
                height: 480px;
            }
        }
    }
}
