#mobileHeaderPicture {
    @include media-breakpoint-up(sm) {
        display: none !important;
        height:0 !important;
    }
}
@include media-breakpoint-down(sm) {
    .body-homepage header {
        background-image:none!important;
    }
}

header {

    height: 70vh;
    @include media-breakpoint-up(sm) {
        height: 100vw;
    }
    @include media-breakpoint-up(lg) {
        height: 75vh;
    }
    @include media-breakpoint-up(xl) {
        height: 85vh;
    }

    .svg-maske,
    .svg-maske path {
        fill: var(--bs-grey-light);
    }
    .wrapper-maske-bg {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    .embed-container {
        overflow: hidden;
        max-width: 100%;
        background-color: transparent;
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
    }

    .embed-container iframe,
    .embed-container object,
    .embed-container embed {
        position: absolute;
        top: 0;
        left: 0;
        //width: 100%;
        height: 100%;
        aspect-ratio: 1 / 1;
    }
    .embed-container.headervideo-4-3 {
        iframe, object, embed {
            aspect-ratio: 4 / 3;
        }
        iframe {
            @include media-breakpoint-down(lg) {
                left: calc((100vw - 100vw / 3 * 4) / 2);
            }
            @include media-breakpoint-down(sm) {
                left: calc((100vw - 70vh / 3 * 4) / 2);
            }
        }
    }
    .embed-container.headervideo-16-9 {
        iframe, object, embed {
            aspect-ratio: 16 / 9;
        }
        iframe {
            @include media-breakpoint-down(lg) {
                left: calc((100vw - 100vw / 9 * 16) / 2);
            }
            @include media-breakpoint-down(sm) {
                left: calc((100vw - 70vh / 9 * 16) / 2);
            }
        }
    }
    
    h1, .h1, h1 p, .h1 p {
        text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.35);
        font-size: 30px;
        line-height: 38px;
        margin-bottom: 40px !important;
        @include media-breakpoint-up(sm) {
            font-size: 50px;
            line-height: 60px;
        }
        @include media-breakpoint-up(md) {
            font-size: 60px;
            line-height: 70px;
        }
        @include media-breakpoint-up(md) {
            font-size: 60px;
            line-height: 70px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 55px;
            line-height: 65px;
        }
        @include media-breakpoint-up(xl) {
            font-size: 60px;
            line-height: 70px;
            text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
        }
        @include media-breakpoint-up(xxl) {
            font-size: 70px;
            line-height: 80px;
        }
        @include media-breakpoint-up(xxxl) {
            font-size: 90px;
            line-height: 100px;
        }
    }

    .arrow-down-content {
        text-align: center;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 40px;
        bottom: 10px;
        @include media-breakpoint-up(md) {
            bottom: 20px;
        }
        @include media-breakpoint-up(xxxxl) {
            bottom: 40px;
        }
        i {
            font-size: 20px;
        }
    }
    
}

body.body-product-overview {
    header {
        position: relative;
        height: 100vw;
        width: 100vw;
        @include media-breakpoint-up(sm) {
            height: 70vw;
        }
        @include media-breakpoint-up(md) {
            height: 60vw;
        }
        @include media-breakpoint-up(lg) {
            height: 50vw;
        }
        @include media-breakpoint-up(xl) {
            position: absolute;
            height: 30vw;
            width: 37vw;
            @include media-breakpoint-up(xxl) {
                width: 35vw;
            }
        }

        .svg-maske,
        .svg-maske path {
            fill: var(--bs-white);
        }
    }
}

