.partnerLogo.ratio-w1-h1,
.partnerLogo.ratio-w1-h2,
.partnerLogo.ratio-w1-h3 {
    max-height: calc((100vw / 4) - 32px);
    width: auto;
    @include media-breakpoint-up(md) {
        max-height: calc((100vw / 8) - 32px);
    }
}
.partnerLogo.ratio-w2-h1,
.partnerLogo.ratio-w3-h1 {
    // max-width: calc((100vw / 2) - 52px);
    max-width: calc(100% + 30px);
    height: auto;
    @include media-breakpoint-up(md) {
        // max-height: calc((100vw / 8) - 32px);
        max-width: calc(100% + 30px);
        width: auto;
    }
}
