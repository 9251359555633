.facts-title {
    font-size: 4rem;

    @include media-breakpoint-up(sm) {
        font-size: 5rem;
    }
    @include media-breakpoint-up(md) {
        font-size: 6rem;
    }
    @include media-breakpoint-up(lg) {
        font-size: 7rem;
    }
    @include media-breakpoint-up(xxl) {
        font-size: 8rem;
    }
}
.facts-beschreibung {
    ul {
        list-style: inside;
    }
}
.no-bg {
    .facts-title {
        color: var(--bs-blue);
    }
}
