input[type='checkbox'] {
    border-radius: 0;
}
select {
    color: var(--bs-black);
}

.form-wrapper {
    padding: 0;
    font-size: 1rem;
    color: var(--bs-dark);

    .fui-alert {
        margin-top: 2rem;
        margin-bottom: 2rem;
        border-radius: 0;
        p {
            margin-bottom: 0;
        }
    }

    input,
    select,
    textarea {
        background-color: var(--bs-grey-light);
        border-radius: 0;
        padding: 0.75rem 1.25rem;
        border:0;
    }

    button,
    input[type='submit'] {
        border-radius: 0;
        @extend .btn, .btn-primary;
    }

    .form-check {
        padding-left: 0;
        display: flex;
        align-items: center;
    }

    input[type='checkbox'] {
        border: 1px solid var(--bs-white);
        background-color: var(--bs-black);
        margin-right: 15px;
        height: 30px;
        width: 30px;
        border:0;
        border-radius: 0;

        &:checked {
            background-color: var(--bs-white);
        }
    }

}


.offcanvas {
    input[type='checkbox'] {
        border: 1px solid var(--bs-white);
        background-color: var(--bs-black);

        &:checked {
            background-color: var(--bs-white);
        }
    }
}

[data-field-type='checkboxes'] .form-check input[type=checkbox],
[data-field-type='agree'] .form-check input[type=checkbox] {
    float: none;
    margin: 0;
    padding: 0;
    margin-right: 15px;
    background-color: var(--bs-grey-light);
    height: 48px;
    width: 48px;
}

.accordion .form-wrapper form {
    padding: 0!important;
}

.col[data-field-handle="city"] {
    order: 2;
}
.col[data-field-handle="zip"] {
    order: 1;
}

.form-wrapper {
    h2 .inactive {
        color: var(--bs-grey-dark);
    }
    .headline-counter h2 {
        line-height: 1.4 !important;
    }
}

.filepond--root {
    border: none;
    background-color: rgb(248,248,244);
    //min-height: 140px;
    border: 1px dot-dash var(--bs-grey-dark);
    .filepond--panel-root {
        background-color: rgb(248,248,244);
        border-radius: 0;
    }
    .filepond--drop-label {
        font-family: 'Helvetica Now', sans-serif !important;
        font-size: calc(1.275rem + .3vw) !important;
        //margin-top: 14px;
    }
    .filepond--drop-label label,
    .filepond--drop-label label button {
        font-family: 'Helvetica Now', sans-serif !important;
    }
    .filepond--label-action {
        background-color: var(--bs-btn-bg);
        border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
        color: var(--bs-btn-color);
        cursor: pointer;
        //display: inline-block;
        font-family: var(--bs-btn-font-family);
        font-size: var(--bs-btn-font-size);
        font-weight: var(--bs-btn-font-weight);
        line-height: var(--bs-btn-line-height);
        //padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
        text-align: center;
        text-decoration: none;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        user-select: none;
        vertical-align: middle;
    }
    .filepond--file-action-button.filepond--action-remove-item {
        left: auto;
        right: .5625em;
    }
    .filepond--panel, .filepond--file-wrapper {
        background-color: var(--bs-grey-light)!important;
        border-radius: 0;
    }
    .filepond--file-info {
        transform: translate3d(5px, 0px, 0px) !important;
    }
    .filepond--file-status-main, .filepond--file-status-sub {
        color: black;
    }
    .filepond--drop-label {
        will-change: unset;
        color: black;
    }
    .filepond--file-info .filepond--file-info-main {
        color: black;
        font-size: 16px;
    }
    .filepond--file-info-sub {
        color: black;
    }
    .filepond--file-action-button {
        background-color: transparent;
        top:13px;
    }

    .text-danger {
        z-index: 1001;
        position: absolute;
        bottom: -16px;
        background: #f2d4cc;
        color: var(--bs-alert-color);
        padding: 4px;
        padding-left: 12px;
        padding-right: 12px;
        width: calc(100% - 32px);
        margin-left: 4px;
    }

}

.prev-next-buttons {
    padding-top: 16px;
    @include media-breakpoint-down(md) {
        display: block !important;
        padding-left:8px;
        padding-right:8px;
        button {
            width: 100%;
            margin-left: 0!important;
            margin-right: 0!important;
            margin-bottom: 8px;
        }
        button[data-submit-action="submit"] {
            text-align: right;
        }
        button[data-submit-action="back"] {
            text-align: left;
        }
    }
}

button[data-submit-action="back"],
button[data-submit-action="submit"] {
    position: relative;
}
button[data-submit-action="back"] {
    background: var(--bs-black);
    color: var(--bs-white);
    border-color: var(--bs-black);
    padding-left: 40px;
}
button[data-submit-action="submit"] {
    padding-right: 40px;
}

button[data-submit-action="back"]:after,
button[data-submit-action="submit"]:after {
    background-repeat: no-repeat;
    content: "";
    height: 15px;
    width: 13px;
    position: absolute;
    top: 11px;
}
button[data-submit-action="back"]:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23FFFFFF' d='M4.7 244.7c-6.2 6.2-6.2 16.4 0 22.6l176 176c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L54.6 272 432 272c8.8 0 16-7.2 16-16s-7.2-16-16-16L54.6 240 203.3 91.3c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0l-176 176z'/%3E%3C/svg%3E");
    left: 13px;
}
button[data-submit-action="submit"]:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23000000' d='M443.3 267.3c6.2-6.2 6.2-16.4 0-22.6l-176-176c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L393.4 240 16 240c-8.8 0-16 7.2-16 16s7.2 16 16 16l377.4 0L244.7 420.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l176-176z'/%3E%3C/svg%3E");
    right: 13px;
}
.form-wrapper .col.agb input[type='checkbox'] {
    flex-shrink: 0;
}
