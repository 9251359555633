//footer custom styles

.social-media-link {
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-right: 16px;
    svg {
        width: 30px;
        height: 30px;
    }
}
.social-media-icon {
    width: 30px;
    height: 30px;
}

footer {

    border-top: 1px solid var(--bs-grey-dark);

    font-family: 'Helvetica Now', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    button {
        font-family: 'Helvetica Now', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
    }

    .icon-contrast-mode {
        height: 27px;
        width: 27px;
    }
    .icon-grayscale-mode {
        height: 27px;
        width: calc(27px * 15.634 / 20.522);
        margin-left: 20px;
    }

    @include media-breakpoint-down(lg) {

        padding-top: 20px;

        #accordionFooter {

            .accordion-item {
                font-family: 'Helvetica Now', sans-serif;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                font-size: 14px;
                color: var(--bs-black);
                border-top: 1px solid var(--bs-grey-dark);
            }

            .accordion-item:last-child {
                border-bottom: 1px solid var(--bs-grey-dark);
            }

            .accordion-header {
                .accordion-button {
                    padding-top: 0;
                    padding-bottom: 0;
                    line-height: 34px;

                    i {
                        position: absolute;
                        right: 0;
                        transform: rotate(-180deg);
                        transition: 0.2s;
                    }
                }

                .accordion-button.collapsed i {
                    transform: rotate(0deg);
                    transition: 0.2s;
                }

                .accordion-button {
                    font-family: 'Helvetica Now', sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: var(--bs-grey-dark);
                }

                .accordion-button:after {
                    content: none;
                }
            }

            .accordion-body {
                padding-top: 0;

                a {
                    font-family: 'Helvetica Now', sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    text-decoration: none;
                }
            }

        }
    }

    @include media-breakpoint-up(lg) {

        padding-top: 32px;

        h2 {
            font-family: 'Helvetica Now', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: var(--bs-grey-dark);
            margin-bottom: 20px;
        }

        .footerlink {
            font-family: 'Helvetica Now', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            text-decoration: none;
        }
    }

}


#cookieAlertWrapper {

    z-index: 999;
    padding: 16px;
    padding-bottom: 0;
    padding-top: 0;
    bottom: 16px;

    @include media-breakpoint-up(lg) {
        padding-left: 60px;
        padding-right: 60px;
        bottom:35px;
    }


    font-family: 'Helvetica Now', sans-serif;
    font-style: normal;
    font-weight: 400;

    margin-bottom: 20px;
    
    h2 {
        font-size: 17px;
        line-height: 1.4; // 24px
        @include media-breakpoint-up(sm) {
            font-size: 22px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 20px;
            line-height: 30px;
        }
        @include media-breakpoint-up(xl) {
            font-size: 22px;
        }
        @include media-breakpoint-up(xxxl) {
            font-size: 26px;
            line-height: 34px;
        }
        @include media-breakpoint-up(xxxxl) {
            font-size: 30px;
            line-height: 38px;
        }
    }
}
