@use 'sass:map';

// Variables
@use './utils/svg';

// Color system
$primary:   hsl(50deg 87% 60%);
$info:      hsl(204deg 97% 64%);
$danger:    hsl(12deg 100% 37%);
$light:     hsl(0deg 0% 87%);
$dark:      hsl(0deg 0% 15%);

:root {
    --primary:   #{$primary};
    --info:      #{$info};
    --danger:    #{$danger};
    --light:     #{$light};
    --dark:      #{$dark};
}

$theme-colors: (
    'primary':   $primary,
    'info':      $info,
    'danger':    $danger,
    'light':     $light,
    'dark':      $dark
);

// Auto calculation contrast ratio setting
$min-contrast-ratio:   4.5;

// Add custom colors to theme-colors
$custom-theme-colors: (
    'yellow':           #E3BF00,
    'red':              #DF5653,
    'blue':             #0D3866,
    'green':        	#7B8F4F,
    'bordeaux':         #8986CA,
    'lavender':         #8986CA,
    'grey-light':       hsl(60, 6%, 85%),
    'grey-mid':         hsl(150, 4%, 69%),
    'grey-dark':        #A6A699,
    'black':            #000000,
    'white':            #FFFFFF

);

// Save as array
$theme-colors: map.merge($theme-colors, $custom-theme-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');

// Spacings
$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer * .25,
    2: $spacer * .5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,

    7: $spacer * 7 / 4,
    8: $spacer * 8 / 4,
    12: $spacer * 12 / 4,
    16: $spacer * 16 / 4,
    22: $spacer * 22 / 4,
    24: $spacer * 24 / 4,
    
    10px: $spacer * 10 / 16,
    15px: $spacer * 15 / 16,
    16px: $spacer * 16 / 16,
    20px: $spacer * 20 / 16,
    25px: $spacer * 25 / 16,
    30px: $spacer * 30 / 16,
    32px: $spacer * 32 / 16,
    40px: $spacer * 40 / 16,
    48px: $spacer * 48 / 16,
    50px: $spacer * 50 / 16,
    60px: $spacer * 60 / 16,
    64px: $spacer * 64 / 16,
    72px: $spacer * 72 / 16,
    80px: $spacer * 80 / 16,
    92px: $spacer * 92 / 16,
    100px: $spacer * 100 / 16,
    110px: $spacer * 110 / 16,
    140px: $spacer * 140 / 16,
    180px: $spacer * 180 / 16,
    220px: $spacer * 220 / 16,
    260px: $spacer * 260 / 16,

);

// border-widths
$border-width: 1px;
$border-widths: (
    1: 1px,
    2: 2px,
);

// Fix spacings
$blockquote-margin-y:           map.get($spacers, 4);
$hr-margin-y:                   map.get($spacers, 4);
$dropdown-item-padding-y:       map.get($spacers, 1);
$dropdown-item-padding-x:       map.get($spacers, 4);
$card-spacer-y:                 map.get($spacers, 4);
$card-spacer-x:                 map.get($spacers, 4);
$card-img-overlay-padding:      map.get($spacers, 4);
$popover-header-padding-x:      map.get($spacers, 4);
$popover-body-padding-y:        map.get($spacers, 4);
$popover-body-padding-x:        map.get($spacers, 4);
$modal-inner-padding:           map.get($spacers, 4);
$alert-padding-y:               map.get($spacers, 4);
$alert-padding-x:               map.get($spacers, 4);
$list-group-item-padding-y:     map.get($spacers, 2);
$list-group-item-padding-x:     map.get($spacers, 4);

// Font sizes (Reset). Use "fs-X" to set font size!
$font-size-base: 1rem;
$h1-font-size: $font-size-base;
$h2-font-size: $font-size-base;
$h3-font-size: $font-size-base;
$h4-font-size: $font-size-base;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;

// General settings
$enable-rounded: false;
$enable-negative-margins: true;

// Forms / Input
$input-disabled-bg:                     var(--bs-grey-light);
$input-focus-border-color:              var(--bs-grey-dark);
$input-focus-box-shadow:                0 3px 5px rgba(0, 0, 0, .075);

// Accordion
$accordion-padding-x:                       0;
$accordion-bg:                              rgba(0,0,0,0);
$accordion-border-width:                    0;
$accordion-button-active-bg:                rgba(0,0,0,0);
$accordion-button-active-color:             var(--bs-black);
$accordion-button-focus-border-color:       '';
$accordion-button-focus-box-shadow:         '';
//$accordion-button-icon:                     svg.svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><path d="M15.477.908,15.49,28.2,1.375,17.679s0,2.155,0,2.155l14.99,11.075L31.375,19.8V17.689L17.24,28.2,17.246.908Z" fill="black"/></svg>');
//$accordion-button-active-icon:              svg.svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><path d="M15.477.908,15.49,28.2,1.375,17.679s0,2.155,0,2.155l14.99,11.075L31.375,19.8V17.689L17.24,28.2,17.246.908Z" fill="black"/></svg>');
$accordion-icon-transform:                  rotate(180deg);

// Buttons
$btn-border-width: 3px;
$btn-box-shadow: inset 0 1px 5px rgba(0, 0, 0, .075);
$btn-focus-width: 2px;
$btn-focus-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .075);
$btn-active-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .2);

// Modal
$modal-backdrop-opacity: .75;
$zindex-modal-backdrop: 3040;
$zindex-modal: 3050;

//Offcanvas
$zindex-offcanvas-backdrop:         3040;
$zindex-offcanvas:                  3050;
$offcanvas-bg-color:                var(--bs-black);
$offcanvas-color:                   var(--bs-white);
$offcanvas-horizontal-width:        500px;

// Breadcrumbs
$breadcrumb-divider: '→';
$breadcrumb-margin-bottom: 0rem;
$breadcrumb-divider-color: var(--bs-black);

// Dropdown
$dropdown-min-width:    5.75rem;

$grid-columns:          8;
$grid-gutter-width:     1rem;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
    xxxl: 1800px,
    xxxxl: 2000px,
);

$spa: 20px;
$spa-sm: 20px;
$spa-md: 30px;
$spa-lg: 30px;
$spa-xl: 30px;
$spa-xxl: 40px;
$spa-xxxl: 50px;
$spa-xxxxl: 50px;
