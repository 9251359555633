body.body-image nav#navigation {
    .col-logo {
        margin-bottom: $spa * 0.5;
        @include media-breakpoint-up(sm) {
            //margin-top: $spa-sm * 1.0;
            margin-bottom: 20px;
        }
        @include media-breakpoint-up(xxl) {
            margin-top: $spa-xxl * 0.5;
        }
    }
}

body.body-image.navigation-fixed nav#navigation {
    .col-logo {
        margin-bottom: 0;
    }
}

nav#navigation {
    z-index: 1002;
    position: absolute;
    padding-top: $spa;

    @include media-breakpoint-up(sm) {
        padding-top: $spa-sm * 1.5;
    }
    @include media-breakpoint-up(md) {
        padding-top: $spa-md * 1.5;
    }

    .nav-item {
        background-color: $white;
        border: 2px solid var(--bs-blue);
        margin-right: 5px;
        padding:5px;
        padding-left:10px;
        padding-right:10px;
        box-shadow: 0 0 15px rgb(0 0 0 / 16%);

        a {
            color: var(--bs-blue);
            font-size: 14px;
            line-height: 22px;
            text-decoration: none;
        }
    }

    .nav-item.nav-item-active {
        background-color: var(--bs-blue);

        a {
            color: $white;
        }
    }

    .nav-item:last-child {
        margin:0;
    }

    .logo {
        fill: $white;

        // height: 53.848px;
        // width: 230.633px;
        height: 22px;
        width: calc(230.633 * 22px / 53.84);
        @include media-breakpoint-up(sm) {
            height: 36px;
            width: calc(230.633 * 36px / 53.84);
        }
    }

    .btn-search {
        height: 24px;
        width: 24px;
        @include media-breakpoint-up(sm) {
            height: 34px;
            width: 34px;
            margin-left:3px;
        }

        padding:0;
        margin:0;
        border:none;
        position: relative;

        .icon-search {
            height: 100%;
            width: 100%;
            left:0;
            top:0;
            position: absolute;
        }
    }

    .btn-burger {
        display: none;
        margin-left:10px;
    }

    .col-breadcrumb {
        display: none;
    }
}


body:not(.body-image) nav#navigation {
    /*
    @include media-breakpoint-down(xl) {
        .col-logo {
            margin-bottom: 0;
        }
    }
    */
    .col-logo {
        margin-bottom: 0;
    }
}

body:not(.body-image) nav#navigation,
body.navigation-fixed nav#navigation {
    position: fixed;
    top: 0;
    padding-bottom: 15px;
    background-color: $white;

    @include media-breakpoint-down(xl) {
        padding-top: 0;
        padding-bottom: 0;
    }

    .height-62px-72px {
        height: 62px;
        @include media-breakpoint-up(xl) {
            height: 72px;
        }
    }
    
    @include media-breakpoint-up(sm) {
        .col-logo {
            margin-top:0;
        }

        .logo {
            height: 22px;
            width: calc(230.633 * 22px / 53.84);
        }
    }
    @include media-breakpoint-up(lg) {
        height: 62px;
        @include media-breakpoint-up(xl) {
            height: 72px;
        }

        padding-top: 0;
        padding-bottom: 0;
    }

    .logo {
        fill: $black;
    }

    .btn-search,
    .col-navi {
        display: none;
    }

    .btn-burger {
        border: none;
        display: block;
        position: relative;
        height: 24px;

        span {
            left:0;
            width: 23px;
            position: absolute;
            border-top: 2px solid black;
            transition: .3s cubic-bezier(0.68, -0.6, 0.32, 1.6);
        }

        span.slice1 {
            top:4px;
        }

        span.slice2 {
            top:11px;
        }

        span.slice3 {
            top:18px;
        }
    }

    .btn-burger:hover,
    .btn-burger:active {
        border:none;
    }

}

body nav#navigation.navigation-open {
    .col-navi,
    .col-breadcrumb {
        display: block;
    }
    
    .col-navi {
        margin-bottom: calc($spa);
        @include media-breakpoint-up(sm) {
            margin-bottom: calc($spa-sm);
        }
        @include media-breakpoint-up(md) {
            margin-bottom: calc($spa-md);
        }
        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
            margin-top:0;
            padding-bottom:0;
            padding-top:0;
        }
        @include media-breakpoint-up(xl) {
            margin-bottom: 0;
            margin-top:0;
        }
    }

    .col-breadcrumb {
        margin-bottom: calc($spa);
        @include media-breakpoint-up(sm) {
            margin-bottom: calc($spa-sm);
        }
        @include media-breakpoint-up(md) {
            margin-bottom: calc($spa-md);
        }
        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }
    }

    @include media-breakpoint-up(lg) {
        .col-navi {
            height: 96px;
            margin:0;

            .navbar-nav {
                padding-bottom: calc($spa-lg);
                @include media-breakpoint-up(xl) {
                    padding-bottom: calc($spa-xl);
                }
                @include media-breakpoint-up(xxl) {
                    padding-bottom: calc($spa-xxl);
                }
                @include media-breakpoint-up(xxxl) {
                    padding-bottom: calc($spa-xxxl);
                }
                @include media-breakpoint-up(xxxxl) {
                    padding-bottom: calc($spa-xxxxl);
                }
            }
        }
    }

    .btn-search {
        display: block;
    }

    .btn-burger {
        span.slice1 {
            top:11px;
            width: 25px;
            transform: rotate(45deg);
        }

        span.slice2 {
            width: 0;
        }

        span.slice3 {
            top:11px;
            width: 25px;
            transform: rotate(-45deg);
        }
    }
}

body.body-image nav#navigation.navigation-open {
    .col-navi {
        margin-bottom: calc($spa / 2);
    }
}

body.navigation-fixed nav#navigation.navigation-open {
    @include media-breakpoint-up(sm) {
        .col-logo {
        //    margin-bottom: 16px;
        }

        .col-navi {
            margin-bottom: 12px;
        }
    }
    @include media-breakpoint-up(lg) {
        .col-logo {
            margin-bottom: 0;
        }
    }

}

body nav#navigation.navigation-open {

    @include media-breakpoint-up(lg) {
        .col-navi {
            .navbar-nav {
                background-color: $white;
            }

            .navbar-nav::before {
                content: '';
                background-color: $white;
                position: absolute;
                z-index: -1;
                left: 0;
                top: 0;
                display: block;
                height: 158px;
                @include media-breakpoint-up(xl) {
                    height: 168px;
                }

                width: 200px;
                @include media-breakpoint-up(xxxxl) {
                    width: 30vw;
                }
            }
        }
    }
}

#searchFullInputField {
    color: white;
}

#searchFullInputField::placeholder {
    color: hsl(60deg 6% 85%);
}

.search-result-card {
    margin: 2.5px;
    box-shadow: 0 0 15px rgb(0 0 0 / 16%);
    border-color: transparent;
    font-size: 14px;
    padding-top: 2.5px;
    padding-bottom: 2.5px;

}

/*
#searchFullResults {
    .search-result-card {
        transition: transform 0.25s ease-in-out;
        transform: scale(1);
    }
    .search-result-card:hover {
        transform: scale(1.02);
    }
}
*/

#searchFullResults {
    .h2-searchresult {
        @include media-breakpoint-up(xxl) {
            font-size: 22px;
            line-height: 30px;
        }
    }
}

:root {
    --language-font-color: white;
    --language-dropdown-bg-color: white;
    --language-dropdown-color: #A6A699;
}

.body-project,
.body-image.navigation-fixed,
.body-default {
    --language-font-color: #A6A699;
}

.btn-group-language {
    .btn-language {
        border-color: transparent;
        color: var(--language-font-color);
        .icon-world {
            color: var(--language-font-color);
            margin-bottom: 1px;
        }
    }
    .btn-language:hover,.btn-language:active {
        border-color: transparent;
        color: var(--language-font-color);
    }
    .dropdown-menu {
        transform: none !important;
        margin-top: 36px !important;
        background-color: transparent !important;
        border-color: transparent !important;
        .dropdown-menu-inner {
            background-color: var(--language-dropdown-bg-color);
            border: 3px solid var(--language-dropdown-color);
            width: 82px;
            .icon-world {
                color: var(--language-dropdown-color);
                margin-bottom: 1px;
            }
            span {
                color: var(--language-dropdown-color);
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .body-image .btn-group-language {
        position: absolute;
        right: 40px;
        top: 20px;
    }
}
@include media-breakpoint-up(lg) {
    .body-project .btn-group-language,
    .body-default .btn-group-language,
    .body-image .btn-group-language {
        position: absolute;
        right: 40px;
        top: 20px;
    }
}

/*
.body-image .btn-group-language {
    background: red !important;
    @include media-breakpoint-up(sm) {
        background: lightcoral !important;
    }
}
.body-image.navigation-fixed .btn-group-language {
    background: orange !important;
    @include media-breakpoint-up(sm) {
        background: orangered !important;
    }
}
.body-default .btn-group-language {
    background: blue !important;
    @include media-breakpoint-up(sm) {
        background: lightblue !important;
    }
}
.body-default.navigation-fixed .btn-group-language {
    background: green !important;
    @include media-breakpoint-up(sm) {
        background: greenyellow !important;
    }
}
*/
