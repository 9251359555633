.square {
    overflow: hidden;
}
.square:before {
    content: '';
    display: block;
    padding-top: 100%;  /* initial ratio of 1:1*/
}

.product-image-border-inside {
    border: none;
}
.product-image-border-inside:before {
    padding-top: calc(100% - 16px);
    border-top: 16px solid var(--bs-yellow);
}
[data-productrange='color-green'], .pc-productCategoriesLCC {
    .product-image-border-inside:before {
        border-top: 16px solid var(--bs-green);
    }
}
[data-productrange='color-bordeaux'], .pc-productCategoriesIoT {
    .product-image-border-inside:before {
        border-top: 16px solid var(--bs-bordeaux);
    }
}



.product-inactive {
    background-color: red;
}
.product-inactive.product-active {
    background-color: green;
}

#productAccordionDesktopLeft, 
#productAccordion {
    color: var(--bs-black);
    button.accordion-button {
        font-size: 17px;
        line-height: 24px;
        padding-top: 6px;
        padding-bottom: 6px;
        @include media-breakpoint-up(xl) {
            font-size: 22px;
            line-height: 42px;
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    .accordion-item {
        color: var(--bs-black);
    }
    .accordion-item.accordion-item-technische-eigenschaften {
        .technische-eigenschaften-name, .technische-eigenschaften-werte {
            font-size: 16px;
        }
    }
}

.border-1-grey {
    border: 1px solid #DBDBD6;
}

h2.h2-product,
h3.h3-product,
h3.h3-katheadline,
h3.h3-slider {
    font-size: 17px;
    line-height: 24px;
}
@include media-breakpoint-up(lg) {
    h3.h3-katheadline {
        font-size: 18px;
        line-height: 30px;
    }
    h2.h2-product,
    h3.h3-slider {
        font-size: 22px;
        line-height: 30px;
    }
}


#product-col-first, .product-col-first-mobile {
    background-color: #DBDBD6;
    .bg-image-cover, a {
        mix-blend-mode: multiply;
    }
}


div.product-col {
    @include media-breakpoint-up(lg) {
        margin-bottom: 10px;
    }
}
.product-col {

    @include media-breakpoint-down(lg) {
        .square {
            width: calc(100% - 24px);
            margin-left: 12px;
            aspect-ratio: 1/1;
        }
    }

    .bg-image-cover {
        @include media-breakpoint-up(lg) {
            //background-origin: content-box;
            padding:0;
        }
    }
    @include media-breakpoint-down(lg) {
        a {
            font-size: 14px;
            line-height: 18px;
        }
    }
    .product-title {
        font-size: 14px;
        line-height: 18px;
        @include media-breakpoint-up(lg) {
            font-size: 11px;
            line-height: 16px;

            bottom:0px;
            left:8px;
            position: absolute;
            max-width: calc(100% - 16px);
            .bg-white {

            }
        }

    }
}
.productimageslinks .slide {
    small {
        font-size: 14px;
        line-height: 18px;
    }
}
.product-col-active .square-overlay:before {
    background-color: hsl(60, 6%, 85%, 0.5);
}

.productimageslinks {

    overflow-x:scroll;
    overflow-y:hidden;
    
    @include media-breakpoint-up(lg) {
        height:calc(((100vw - 90px) / 8 * 5 / 16 * 9));
        overflow-x:hidden;
        overflow-y:scroll;

        scrollbar-color: var(--bs-grey-light) var(--bs-yellow);
        &::-webkit-scrollbar { width: 10px; height: 10px; }
        &::-webkit-scrollbar-thumb { background: var(--bs-yellow); }
        &::-webkit-scrollbar-track { background: var(--bs-grey-light); }
    }

    @include media-breakpoint-up(xl) {
        height:calc(((100vw - 160px) / 8 * 5 / 16 * 9));
    }
    @include media-breakpoint-up(xxl) {
        height:calc(((100vw - 200px) / 8 * 5 / 16 * 9));
    }
    @include media-breakpoint-up(xxxl) {
        height:calc(((100vw - 220px) / 8 * 5 / 16 * 9));
    }
    @include media-breakpoint-up(xxxxl) {
        height:606px;
    }
}
.pwrapper {
    height:calc(((100vw - 100px) / 8 * 5 / 16 * 9 / 3));
    @include media-breakpoint-up(xl) {
        height:calc(((100vw - 160px) / 8 * 5 / 16 * 9 / 3));
    }
    @include media-breakpoint-up(xxl) {
        height:calc(((100vw - 200px) / 8 * 5 / 16 * 9 / 3));
    }
    @include media-breakpoint-up(xxxl) {
        height:calc(((100vw - 220px) / 8 * 5 / 16 * 9 / 3));
    }
    @include media-breakpoint-up(xxxxl) {
        height:202px;
    }
}

/*

    xl: 1200px,
    xxl: 1400px,
    xxxl: 1800px,
    xxxxl: 2000px,
 */

.pwrapper a {
    background-position: top;
    background-size: 50%;
    background-repeat: no-repeat;
}


.material-image {
    border-bottom-right-radius: 1.5rem;
}


.container-single-product {

    .button-slide-up-wrapper,
    .button-slide-down-wrapper {
        position: absolute;
        background-image: linear-gradient(to top, transparent, white);
        z-index: 1;
        height: 48px;
    }
    .button-slide-down-wrapper {
        background-image: linear-gradient(to bottom, transparent, white);
    }

    button.slide-up,
    button.slide-down {
        position: absolute;
        border: 1px solid var(--bs-black);
        width: 32px;
        height: 32px;
        border-radius: 100%;
        left: 0;
        right: 0;
        top: 8px;
        margin: auto;
        background-color: var(--bs-white);
    }

    @include media-breakpoint-up(lg) {
        .produktbild-wrapper {
        //    padding-left:16px !important;
        }
    }

    .container-productfamily {

        @include media-breakpoint-down(lg) {
            overflow: hidden;
            width: calc(100vw - 26px);
            @include media-breakpoint-up(sm) {
                width: calc(100vw - 40px);
            }
            @include media-breakpoint-up(md) {
                width: calc(100vw - 58px);
            }
        }
        #productfotosmobile {
            height: calc(50vw);
            @include media-breakpoint-up(sm) {
                height: 43vw;
            }
            @include media-breakpoint-up(md) {
                height: 40vw;
            }
        }

        #productfotosdesktop {
            width: calc(100% - 8px);
            min-height: 200px;
        }

        .row-productfamily {

            overflow-x: hidden;
            @include media-breakpoint-down(lg) {
                max-height: calc(50vh);
            }

            .square:before {
                padding-top: 90%;
                @include media-breakpoint-up(lg) {
                    padding-top: 60%;
                }
            }
            .square-overlay:before {
            //    padding-top: 100%;
            }

        }

        .product-filter-inactive {
            .square-overlay:before {
                background-color: rgba(255, 255, 255, 0.8);
            }
            .product-title {
                opacity: 0.2;
            }
        }
        .product-filter-active {
            .square-overlay:before {
                //background-color: rgba(255, 255, 255, 0.5);
            }
            .product-title {
                opacity: 1;
            }
        }
        
    }
}

main.product-default {
    .container-cta2cols {
        .container-fluid {
            margin-bottom: 0;
        }
    }
    .banklattencta .container-cta2cols {
        .container-fluid {
            margin-bottom: 2rem;
            @include media-breakpoint-up(md) {
                margin-bottom: 3rem;
            }
            @include media-breakpoint-up(lg) {
                margin-bottom: 5rem;
            }
        }
    }
}

.product-image-wrapper {
    position: relative;
    img, svg {
        position: absolute;
        bottom: 0;
        right: 0;
        height: auto;
        width: auto;
        max-height: 20%;
        max-width: 20%;
    }
}

.product-icon-wrapper {
    img.icon-over, svg {
        position: absolute;
        bottom: 10px;
        right: 10px;
        height: auto;
        width: auto;
        max-height: 40%;
        max-width: 30%;
    }
}

.col-product-text-more {
    display: none;
}
@include media-breakpoint-down(lg) {
    .col-product-text {
        height: 96px;
        overflow: hidden;
        position: relative;
    }
    .col-product-text:after {
        content: '';
        position: absolute;
        left:0;
        top:0;
        width:100%;
        height: 100%;
        background-image: linear-gradient(to bottom, transparent 0%, white 69%);
        z-index: 0;
    }
    .col-product-text-more {
        position: absolute;
        width: 36px;
        height: 36px;
        border-radius: 100%;
        left: 0;
        right: 0;
        bottom: 10px;
        margin: auto;
        background-color: var(--bs-white);
        font-size: 12px;
        line-height: 1;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
        z-index: 1;
    }
    .col-product-text-more:hover {
        cursor: pointer;
    }
    .col-product-text .col-product-text-more {
        display: flex;
    }
}

@include media-breakpoint-up(lg) {
    .line-between-cols .col-line {
        position: relative;
    }
    .line-between-cols .col-line:after {
        content : '';
        border-right: 1px solid #DBDBD6;
        height: 100%;
        position: absolute;
        top:0;
        right: 0;
    }
    .line-between-cols.line-between-cols-small .col-line:after {
        height: calc(100% - 34px);
        margin-top:17px;
    }

    .col-line.col-line-top-15:after {
        height: calc(100% - 34px);
        margin-top:17px;
    }

}

.line-slider-headline {
    position: relative;
}
.line-slider-headline:after {
    content: '';
    position: absolute;
    top:0;
    left:0;
    width: 100vw;
    height: 0px;
    border-top: 1px solid #DBDBD6;

    @include media-breakpoint-up(xxxxl) {
        left: calc((1806px - 100vw) / 2);
    }
}

.line-slider,
.line-right-end {
    height: 1px;
    position: relative;
}
.line-right-slider:after,
.line-right-end:after {
    content : '';
    border-top: 1px solid #DBDBD6;
    width: 100vw;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
}


.bg-image-cover-product {
    height: calc(100% - 16px);
    background-position: top;
    background-size: contain;
    padding: 0;
}

.cursor-pointer {
    cursor: pointer;
}

.variante-lvk {
    height: 30px;
}

#variantsresultlist {
    .variant {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.16);
        border-color: transparent;
        margin: 2.5px;
        margin-top: 7.5px;
        margin-bottom: 7.5px;
        padding:15px;
        padding-top: 2.5px;
        padding-bottom: 2.5px;
        display: inline-block;
        background-color: #fff;
        text-align: left;
        button.accordion-button {
            font-size: 17px;
            line-height: 24px;
            padding-top: 1px;
            padding-bottom: 1px;
            @include media-breakpoint-up(xl) {
                font-size: 22px;
                line-height: 30px;
                padding-top: 6px;
                padding-bottom: 6px;
            }
        }
    }
    .accordion-button.collapsed::after {
        background-image: url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'%23A6A699\' height=\'1em\' viewBox=\'0 0 448 512\'%3E%3Cpath d=\'M256 80V48H192V80 224H48 16v64H48 192V432v32h64V432 288H400h32V224H400 256V80z\'/%3E%3C/svg%3E%0A') !important;
    }
    .accordion-button:not(.collapsed)::after {
        background-image: url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'%23A6A699\' height=\'1em\' viewBox=\'0 0 448 512\'%3E%3Cpath d=\'M432 288H400L48 288H16l0-64 32 0 352 0 32 0v64z\'/%3E%3C/svg%3E') !important;
    }

}

.downloadbutton {
    span {
        padding-right: 0.5rem;
    }
}
.list-technische-eigenschaften {
    list-style-type: none;
    li {
        margin-bottom: 16px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 0px;
        }
    }
    li:last-of-type {
        margin-bottom: 0px;
    }
    li span {
        font-weight: normal;
        display: inline-block;
        width: 100%;
        @include media-breakpoint-up(lg) {
            width: 75%;
            margin-bottom: 0px;
        }
    }
    li span:first-of-type {
        font-weight: bold;
        @include media-breakpoint-up(lg) {
            width: 25%;
        }
    }
}

@include media-breakpoint-up(lg) {
    .aspect-ratio-lg-16-9 {
        aspect-ratio: 16/9;
    }
}

.banklattenwrapper {
    border: 1px solid #cccccc;
    height: 400px;
    @include media-breakpoint-up(md) {
        height: 200px;
    }
    div {
        height: 50%;
        @include media-breakpoint-up(md) {
            height: 100%;
        }
    }
    .btn:hover {
        text-decoration: none;
    }
}
